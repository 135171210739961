import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/authApi';
import { accountApi } from '../api/accountApi';
import { teamsApi } from '../api/teamsApi';
import { resetInstanceAuthorization } from '../utils/axios';
import {
  SOURCER,
  SOURCER_MANAGER,
  CREATOR_SOURCER,
  CREATOR_SOURCER_MANAGER,
  CONTENT_REVIEWER
} from '../constants';

const initialState = {
  isAuthenticated: false,
  user: null,
  isInitialized: false,
  teams: []
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, teams } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      user,
      teams
    };
  },
  LOGIN: (state, action) => {
    const { user, teams } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      teams
    };
  },
  UPDATE_USER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  GET_TEAMS: (state, action) => {
    const { teams } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      teams
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const identityRole = (role) => {
  return {
    isCreatorSourcer: role === CREATOR_SOURCER,
    isCreatorManager: role === CREATOR_SOURCER_MANAGER,
    isSourcer: role === SOURCER,
    isSourcerManager: role === SOURCER_MANAGER,
    isContentReviewer: role === CONTENT_REVIEWER
  };
};

const dispatchStartupPayload = async (user, dispatchFunc, dispatchType) => {
  try {
    const roleDetails = identityRole(user.role);
    const userWithRoleDetails = {
      ...user,
      ...roleDetails
    };

    const partialPayload = {
      isAuthenticated: true,
      user: userWithRoleDetails
    };

    // Load startup data based on user role
    if (userWithRoleDetails.isSourcer || userWithRoleDetails.isSourcerManager) {
      await teamsApi
        .getTeams(user.id)
        .then((res) => {
          const payload = Object.assign(partialPayload, {
            teams: res.data?.data
          });
          dispatchFunc({
            type: dispatchType,
            payload
          });
        })
        .catch(() => {
          throw new Error('Unable to load the page');
        });
    } else if (
      userWithRoleDetails.isContentReviewer ||
      userWithRoleDetails.isCreatorSourcer ||
      userWithRoleDetails.isCreatorManager
    ) {
      dispatchFunc({
        type: dispatchType,
        payload: partialPayload
      });
    } else {
      throw new Error('Current user role not identified');
    }
  } catch (error) {
    console.error('Error loading startup data:', error);
    throw new Error('Error loading startup data');
  }
};

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUserAccount: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      if (authApi.isAuthenticated()) {
        const user = await accountApi.me();
        await dispatchStartupPayload(user, dispatch, 'INITIALIZE');
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    initialize();
  }, [dispatch]);

  const login = async (email, password, rememberMe) => {
    const response = await authApi.login({ email, password });
    const resData = response.data;

    if (resData?.data) {
      if (
        [
          SOURCER,
          SOURCER_MANAGER,
          CREATOR_SOURCER,
          CREATOR_SOURCER_MANAGER,
          CONTENT_REVIEWER
        ].includes(resData?.data.user.role)
      ) {
        const { access, refresh, user } = resData.data;
        authApi.setRememberMe(rememberMe);
        authApi.setAccessRefreshTokens(access, refresh);
        resetInstanceAuthorization(access);
        await dispatchStartupPayload(user, dispatch, 'LOGIN');
      } else {
        return {
          notAllowed: new Error(
            'Your role does not allow you to access the Sourcing Portal'
          )
        };
      }
    }
    return response;
  };

  const updateUserAccount = async (data, userId) => {
    const response = await accountApi.updateUserInfo(data, userId);
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user: response.data
      }
    });
  };

  const logout = async () => {
    authApi.logout();
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        updateUserAccount
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
