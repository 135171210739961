/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CssBaseline, ThemeProvider, Button, Snackbar } from '@mui/material';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import Router from './routes';
import { createCustomTheme } from './theme';
import * as serviceWorker from './serviceWorkerReg';
import useMounted from './hooks/useMounted';

const App = () => {
  const { settings } = useSettings();
  const auth = useAuth();
  const mounted = useMounted();

  useScrollReset();

  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});

  const onSWUpdate = (registration) => {
    setNewVersionAvailable(true);
    setWaitingWorker(registration.waiting);
  };

  const reloadPage = () => {
    setNewVersionAvailable(false);
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    waitingWorker.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onSWUpdate });
    }
  }, [mounted]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={newVersionAvailable}
        message="A new version is available!"
        onClick={reloadPage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={
          <Button color="inherit" size="small" onClick={reloadPage}>
            Update
          </Button>
        }
      />
      <CssBaseline />
      {auth.isInitialized ? <Router /> : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
