import axios from 'axios';
import { authApi } from '../api/authApi';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 1000 * 40,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

const redirectToLoginPage = () => {
  window.location.href = '/login';
};

export const resetInstanceAuthorization = (accessToken) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = authApi.getAccessToken();
    config.headers['Content-Type'] = 'application/json';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    /* eslint operator-linebreak: 0 */
    if (error.response && error.response.status === 401) {
      const refreshToken = authApi.getRefreshToken();
      if (refreshToken) {
        const validToken = authApi.isValidToken(refreshToken);
        if (validToken) {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_HOST}jwt/refresh/`,
              {
                refresh: refreshToken
              }
            );
            const { access, refresh } = response.data.data;
            // update the tokens in the storage
            authApi.setAccessRefreshTokens(access, refresh);

            // update axios instance auth
            resetInstanceAuthorization(access);

            // Make the same request with the new token
            originalRequest.headers.Authorization = `Bearer ${response.data.data.access}`;
            return axiosInstance(originalRequest);
            // eslint-disable-next-line no-empty
          } catch (refreshTokenError) {
            if (
              refreshTokenError.response &&
              refreshTokenError.response.status === 401
            ) {
              authApi.logout();
              redirectToLoginPage();
            }
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
